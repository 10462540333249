import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../stylesheets/company.scss"

const CompanyPage = () => (
  <Layout>
    <SEO
      title="会社情報"
      description="道新インタラクティブ株式会社の会社情報です。会社概要、沿革、所在地のマップ等の情報を掲載しています。"
    />
    <div className="companyPage-MainVisual">
      <div className="companyPage-MainVisual-headingWrapper">
        <h1 className="companyPage-MainVisual-heading">会社情報</h1>
        <p className="companyPage-MainVisual-subHeading">Company</p>
      </div>
    </div>
    <section className="companyPage-ProfileSection">
      <div className="companyPage-ProfileSection-inner">
        <h2 className="companyPage-ProfileSection-heading">
          概要{" "}
          <span className="companyPage-ProfileSection-subHeading">Profile</span>
        </h2>
        <dl className="companyPage-ProfileSection-list">
          <dt className="companyPage-ProfileSection-term">会社名</dt>
          <dd className="companyPage-ProfileSection-description">
            道新インタラクティブ株式会社
          </dd>
        </dl>
        <dl className="companyPage-ProfileSection-list">
          <dt className="companyPage-ProfileSection-term">本社</dt>
          <dd className="companyPage-ProfileSection-description">
            北海道札幌市中央区大通東4丁目1番地
          </dd>
        </dl>
        <dl className="companyPage-ProfileSection-list">
          <dt className="companyPage-ProfileSection-term">設立</dt>
          <dd className="companyPage-ProfileSection-description">
            2021年4月1日
          </dd>
        </dl>
        <dl className="companyPage-ProfileSection-list">
          <dt className="companyPage-ProfileSection-term">代表者名</dt>
          <dd className="companyPage-ProfileSection-description">
            代表取締役社長　沼田昌広
          </dd>
        </dl>
        <dl className="companyPage-ProfileSection-list">
          <dt className="companyPage-ProfileSection-term">資本金</dt>
          <dd className="companyPage-ProfileSection-description">
            3000万円（資本準備金含む）
          </dd>
        </dl>
        <dl className="companyPage-ProfileSection-list">
          <dt className="companyPage-ProfileSection-term">事業内容</dt>
          <dd className="companyPage-ProfileSection-description">
            求人情報サイト運営事業
            <br />
            採用コンサルティング事業
          </dd>
        </dl>
      </div>
    </section>
    <section className="companyPage-HistorySection">
      <div className="companyPage-HistorySection-inner">
        <h2 className="companyPage-ProfileSection-heading">
          沿革{" "}
          <span className="companyPage-ProfileSection-subHeading">History</span>
        </h2>
        <dl className="companyPage-HistorySection-list">
          <dt className="companyPage-HistorySection-term">2021年4月</dt>
          <dd className="companyPage-HistorySection-description">
            道新インタラクティブ株式会社を創立
          </dd>
        </dl>
      </div>
    </section>
    <section className="companyPage-AccessSection">
      <div className="companyPage-AccessSection-inner">
        <h2 className="companyPage-AccessSection-heading">
          所在地{" "}
          <span className="companyPage-AccessSection-subHeading">Access</span>
        </h2>
        <div className="companyPage-AccessSection-areaWrapper">
          <div className="companyPage-AccessSection-areaTextWrapper">
            <p className="companyPage-AccessSection-areaTitle">
              道新インタラクティブ株式会社
            </p>
            <p className="companyPage-AccessSection-areaAddress">
              〒 060-0041
              <br />
              北海道札幌市中央区大通東4丁目1番地
            </p>
          </div>
          <iframe
            title="google map"
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2874.885413314909!2d141.3603776739864!3d43.062104129279746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1z6YO15L6_55Wq5Y-3IOWMl-a1t-mBk-acreW5jOW4guS4reWkruWMuuWkp-mAmuadsTTkuIHnm64x55Wq5Zyw!5e0!3m2!1sja!2sjp!4v1730438063775!5m2!1sja!2sjp"
            className="companyPage-AccessSection-areaMap"
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      </div>
    </section>
  </Layout>
)

export default CompanyPage
